/**
 * Our "DeleteConfirmation" class to handle any deletion requests.
 */
class DeleteConfirmation {
    /**
     * Set up the delete class
     *
     * @param  Element  elem
     */
    constructor(elem) {
        this.elem = elem;
        this.$elem = $(elem);

        this.$elem.on('click', this.prompt.bind(this));
    }

    /**
     * Show the prompt to confirm that the user wants to delete the entity.
     *
     * @param  Event  e
     * @return void
     */
    prompt(e) {
        var prompt = confirm('Are you sure you want to delete this? This action cannot be undone.');

        // If the user chose "no", stop the event.
        if (! prompt) {
            return e.preventDefault();
        }
    }
}

/**
 * Init any functionality that needs initiating.
 */
$('[data-delete-confirm]').each(function () {
    new DeleteConfirmation(this);
});