
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

 require('./bootstrap');

/**
 * Load the components to be used
 */
require('./components/charts');
require('./components/datepicker');
require('./components/personal-licence');
require('./components/premises-licence');
require('./components/delete-confirmation');
require('./components/application-tasks');
