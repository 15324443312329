module.exports = (function ($) {
    var selectors = {
        licenceType: '#licenceType',
        hoursTypeSelector: '[data-licence-types]',
    };

    var PremisesLicence = {

        init: function () {
            PremisesLicence.toggle();
            $(selectors.licenceType).on('change', PremisesLicence.toggle);
        },

        /**
         * Show/hide the opening/licence hours section depending on the
         * licence type.
         */
        toggle: function () {
           const selectedType = $(selectors.licenceType).val() || 'standard';

           $(selectors.hoursTypeSelector).each(function(i, column) {
                const licenceTypes = $(column).data('licenceTypes');

                if (licenceTypes.includes(selectedType)) {
                    $(column).show();
                } else {
                    $(column).hide();
                }
           })

            const numberOfHoursColumns = $(`${selectors.hoursTypeSelector}:visible`).length;
            const columnWidth = 12 / numberOfHoursColumns;

            $(selectors.hoursTypeSelector).each(function (i, column) {
               $(column)
                   .removeClass(['col-md-3', 'col-md-6'])
                   .addClass(`col-md-${columnWidth}`);
            });
        }
    }

    PremisesLicence.init();
})(jQuery);
