try {
    document.querySelector('[data-select-all]').addEventListener('change', function (e) {
        const selector = e.target.dataset.selectAll;
        const checked = e.target.checked;

        const checkboxes = document.querySelectorAll(`input[name^=${selector}]`);
        checkboxes.forEach(checkbox => {
            checkbox.checked = checked;
        });
    });
} catch (e) {
    // Not on this page
}

try {
    document.querySelector('[data-change-task-owner]').addEventListener('click', function () {
        const tasks = Array.from(document.querySelectorAll('[data-task-selector]:checked'))
            .map(input => input.value);

        if (tasks.length === 0) {
            alert('Please select at least 1 task');

            return;
        }

        const $modal = $('#modal_change-task-owner').modal();
        $modal.find('[data-change-owner-task-ids]').val(tasks);
    });
} catch (e) {
    // Not on this page
}
