
window._ = require('lodash');
window.Popper = require('popper.js').default;

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.$ = window.jQuery = require('jquery');
    // window.Morris = require('morris-js-module/morris')(window.$);
    window.Morris = require('./vendor/Morris.js')(window.$);

    require('bootstrap');
} catch (e) {}

/**
 * Now we'll try and load in the datepicker that we want to use.
 */
require('@fengyuanchen/datepicker');
