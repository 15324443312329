module.exports = (function ($, window) {
    // Define the colours
    var colours = [
        ['#f1c400', '#007a33', '#84bd00'],
        ['#f1c400', '#007a33', '#84bd00'],
        ['#84bd00', '#de6000', '#cc0000'],
        ['#f1c400', '#007a33', '#84bd00']
    ];

    // The Charts functionality
    window.Charts = {
        selectors: {
            charts: '.dashboard--chart'
        },
        /**
         * Initiate the charts.
         */
        init: function (data) {
            this.charts().each(function (i) {
                var data = '#' + $(this).attr('id') + '-json';
                    data = $.trim($(data).html());
                    data = JSON.parse(data);

                // Create the chart
                Morris.Donut({
                    element: this,
                    colors: colours[i],
                    data: data,
                    resize: true
                });
            });
        },

        charts: function () {
            return $(this.selectors.charts);
        },
    }
})(jQuery, window);
