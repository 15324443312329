module.exports = (function ($) {
    var selectors = {
        licenceType: '#personal_licence_type',
        retraining: '.scplh-retraining'
    };

    var PersonalLicence = {
        /**
         * Initialise the personal licence functionality.
         */
        init: function () {
            // Toggle the SCPLH retraining section by default
            PersonalLicence.toggle();

            // When the licence type changes, we want to toggle the SCPLH
            // retraining section if they've chosen the Scottish licence.
            $(selectors.licenceType).on('change', PersonalLicence.toggle);
        },

        /**
         * Show/hide the SCPLH retraining section depending on the
         * licence type.
         */
        toggle: function () {
            if ($(selectors.licenceType).val() == 'scplh') {
                $(selectors.retraining).show();
            } else {
                $(selectors.retraining).hide();
            }
        }
    }

    PersonalLicence.init();
})(jQuery);
